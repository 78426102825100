import { useSession } from 'views/Auth/hooks';
import { Amplify } from 'aws-amplify';
import { Route, Routes } from 'react-router-dom';
import {
    CreateNewPasswordForm,
    ForgotPasswordForm,
    SetupTwoFactorForm,
    SignInForm,
    VerificationCodeForm,
} from 'views/Auth/forms';
import AuthPage from 'views/Auth/AuthPage';
import FileTransferPage from 'views/FileTransfer/FileTransferPage';
import { useLocation, useNavigate } from 'react-router';
import Loader from 'components/Loader';
import FallbackError from 'components/FallbackError';
import MissingPoolPage from 'views/MissingPool/MissingPoolPage';

import LogoutPage from 'views/Logout/LogoutPage';
import { isNavigable } from 'utils/nav_utils';

import VerifyEmailForm from 'views/Auth/forms/VerifyEmailForm';
import useSecureApp from 'views/Auth/hooks/useSecureApp';

export default function Router() {
    const { userPoolClientId, userPoolId, loading, cognitoUserId } =
        useSession();
    const navigate = useNavigate();
    const location = useLocation();

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: userPoolClientId || '',
                userPoolId: userPoolId || '',
            },
        },
    });

    useSecureApp({ loadingSession: loading, cognitoUserId });

    if (loading) return <Loader />;

    if (!userPoolClientId || !userPoolId) {
        if (isNavigable(location.pathname)) navigate('/missing-pool');
    }

    return (
        <div className="rounded-xl bg-white px-20 pb-24 pt-16 text-center z-10 w-[400px]">
            <Routes>
                <Route path="/" element={<FileTransferPage />} />
                <Route
                    path="/error"
                    element={<FallbackError errorMessage={null} />}
                />
                <Route path="auth" element={<AuthPage />}>
                    <Route path="signin" element={<SignInForm />} />
                    <Route
                        path="verification-code"
                        element={<VerificationCodeForm />}
                    />
                    <Route
                        path="new-password"
                        element={<CreateNewPasswordForm />}
                    />
                    <Route
                        path="setup-two-factor"
                        element={<SetupTwoFactorForm />}
                    />
                    <Route
                        path="forgot-password"
                        element={<ForgotPasswordForm />}
                    />
                </Route>
                <Route path="verify-email" element={<VerifyEmailForm />} />
                <Route path="missing-pool" element={<MissingPoolPage />} />
                <Route path="logout" element={<LogoutPage />} />
            </Routes>
        </div>
    );
}
